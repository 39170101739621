import { NavLink, useNavigate } from "react-router-dom"
import styles from "./Sidebar.module.css"
import { useState, useEffect } from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DehazeRoundedIcon from '@mui/icons-material/DehazeRounded';

export default function Sidebar() {

    const [condition, setCondition] = useState<boolean>(false);
    const [toggle1, setToggle1] = useState<boolean>(false);
    const [toggle2, setToggle2] = useState<boolean>(false);
    const [toggle3, setToggle3] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        setCondition(false);
        setToggle1(false);
        setToggle2(false);
        setToggle3(false);
    }, [navigate])

    const open1 = () => {
        setToggle1(!toggle1);
        setToggle2(false);
        setToggle3(false);
    }

    const open2 = () => {
        setToggle1(false);
        setToggle2(!toggle2);
        setToggle3(false);
    }

    const open3 = () => {
        setToggle1(false);
        setToggle2(false);
        setToggle3(!toggle3);
    }
    return (
        <div className={styles.nav}>
            <input type="checkbox" className={styles.check} id="checkbox" checked={condition} onChange={() => setCondition(!condition)}></input>
            <div className={styles.header}><label htmlFor="checkbox"><DehazeRoundedIcon style={{ color: "white", fontSize: 40, position: "absolute", right: 10, top: 3 }} /></label></div>
            <label htmlFor="checkbox" className={styles.back}></label>
            <aside className={styles.sidebar}>
                <div className={styles.sticky}>
                    <div className={styles.ul}>
                        <NavLink to="/" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.list}><WarehouseIcon style={{ marginRight: 10 }} />在庫一覧</li></NavLink>
                        <li className={styles.liOpen} onClick={() => open1()}><SyncAltIcon style={{ marginRight: 10 }} />入出庫{toggle1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</li>
                        {toggle1 ? <div className={styles.toggleUl}>
                            <NavLink to="/control" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>在庫一覧から入出庫</li></NavLink>
                            <NavLink to="/ordered" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>発注済未入庫から入庫</li></NavLink>
                            <NavLink to="/entering" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>登録製品から入庫</li></NavLink>
                        </div> : null}
                        <li onClick={() => open2()} className={styles.liOpen}><Inventory2TwoToneIcon style={{ marginRight: 10 }} />発注{toggle2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</li>
                        {toggle2 ? <div className={styles.toggleUl}>
                            <NavLink to="/order" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>発注</li></NavLink>
                            <NavLink to="/history" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>発注履歴</li></NavLink>
                            <NavLink to="/Suppliers" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>発注先リスト</li></NavLink>
                            {/* <li className={styles.togglelist}>受信メール確認</li> */}
                        </div> : null}
                        <NavLink to="/PriceList" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.list}><AssignmentIcon style={{ marginRight: 10 }} />価格表</li></NavLink>
                    </div>
                    <div className={styles.ul2}>
                        <li className={styles.liOpen} onClick={() => open3()}><ContentPasteSearchIcon style={{ marginRight: 10 }} />管理{toggle3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</li>
                        {toggle3 ? <div className={styles.toggleUl}>
                            <NavLink to="/SupplierTable" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>発注先の編集</li></NavLink>
                            <NavLink to="/Manufacture" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>メーカーの編集</li></NavLink>
                            <NavLink to="/Products" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>製品の編集</li></NavLink>
                            <NavLink to="/constant" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>定数の編集</li></NavLink>
                            <NavLink to="AutomaticOrderingTable" className={({ isActive, isPending }) => isPending ? "" : isActive ? styles.active : styles.link}><li className={styles.togglelist}>自動発注管理</li></NavLink>
                        </div> : null}
                    </div>
                </div>
            </aside >
        </div>
    )

}