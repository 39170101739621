import styles from "./AutomaticOrdering.module.css"
import { useState, useEffect } from "react";
import { SupplierData } from "./SupplierTable";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

type Product = {
    productname: string;
    constant: number;
    office: string;
    supplier: string;
}

type selectNumber = number[];

export default function AutomaticOrdering() {

    const navigate = useNavigate();

    const [selectedOffice, setSelectedOffice] = useState<string>("");
    const [suppliers, setSuppliers] = useState<SupplierData[]>([]);
    const [selectedSupplier, setSelectedSupplier] = useState<string>("");
    const [selectedDay, setSelectedDay] = useState<string[]>([]);
    const [selectedHour, setSelectedHour] = useState<string>("");
    const [selectedMinute, setSelectedMinute] = useState<string>("");
    const [trigger, setTrigger] = useState<string>("");
    const [detailTrigger, setDetailTrigger] = useState<boolean>(false);
    const [products, setProducts] = useState<Product[]>([]);
    const [detailTrigger2, setDetailTrigger2] = useState<string>("");
    const [productNames, setProductNames] = useState<string[]>([]);
    const [selectSupplierConstant, setSelectSupplierConstant] = useState<number[]>([]);
    const [selectProductConstant, setSelectProductConstant] = useState<selectNumber[]>([]);
    const [selectedSupplierConstant, setSelectedSupplierConstant] = useState<number>(0);
    const [selectedProductConstant, setSelectedProductConstant] = useState<number[]>([]);
    const [selected, setSelected] = useState("");
    const [submit, setSubmit] = useState<boolean>(false);


    const office = [
        "函館店", "札幌店", "釧路店", "室蘭店"
    ]

    const dayofweek = [
        {
            name: "月",
            value: "1"
        }, {
            name: "火",
            value: "2"
        }, {
            name: "水",
            value: "3"
        }, {
            name: "木",
            value: "4"
        }, {
            name: "金",
            value: "5"
        }
    ]

    const hour = ["9", "10", "11", "12", "13", "14", "15", "16", "17"]
    const minutes = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"]

    const triggerList = [
        {
            id: "timing",
            name: "condition",
            text: "条件をつけずに選択したタイミングで発注する。"
        },
        {
            id: "carriage",
            name: "condition",
            text: "製品の不足分が送料無料適用額を超えていたら発注する。"
        },
    ]

    const detailTriggerList = [{
        text: "選択した発注先の定数を1以上に設定している全製品に適用",
        value: "supplierTrigger"
    },
    {
        text: "選択した発注先の定数を1以上に設定している製品の中から指定の製品に適用",
        value: "productTrigger"
    }
    ]

    useEffect(() => {
        const fetchSupplier = async () => {
            const response = await fetch("https://api.poweb.jp/getsupplier")
            const data = await response.json();
            setSuppliers(data);
        }
        fetchSupplier();
    }, []);

    useEffect(() => {
        const fetchConstantProducts = async () => {
            const response = await fetch("https://api.poweb.jp/getconstantproducts", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "supplier": selectedSupplier,
                    "office": selectedOffice
                })
            })
            const data: Product[] = await response.json();
            let newProduct = [...productNames];
            const filterDupplication = data.filter(product => !newProduct.includes(product.productname));
            setProducts(filterDupplication);
        }
        fetchConstantProducts();
    }, [selectedSupplier, productNames, selectedOffice])

    useEffect(() => {
        const fetchConstantSupplier = async () => {
            const response = await fetch("https://api.poweb.jp/getconstantsupplier", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "supplier": selectedSupplier,
                    "office": selectedOffice
                })
            })
            const data = await response.json();
            let triggerQuantity: number[] = [];
            for (let i = 0; data.min_constant > i; i++) {
                triggerQuantity.push(i)
            }
            setSelectSupplierConstant(triggerQuantity);
        }
        fetchConstantSupplier();
    }, [selectedSupplier, selectedOffice])

    const handleOffice = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOffice(e.target.value);
    }

    const handleSupplier = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedSupplier(e.target.value);
    }

    const handleDay = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (selectedDay.includes(e.target.value)) {
            setSelectedDay(selectedDay.filter((item) => item !== e.target.value))
        } else {
            setSelectedDay((prev) => [...prev, e.target.value]);
        }
    }

    const handleHour = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedHour(e.target.value)
    }
    const handleMinute = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedMinute(e.target.value)
    }
    const handleTrigger = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTrigger(e.target.value);
    }

    const handleDetail = () => {
        setDetailTrigger((prev) => (!prev))
    }

    const handleTrigger2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDetailTrigger2(e.target.value);
    }

    const pushProducts = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setProductNames((prev) => [...prev, e.target.value]);
        const index = products.findIndex(product => product.productname === e.target.value);
        const minconstant = products[index].constant;
        let triggerQuantity: number[] = [];
        for (let i = 0; minconstant > i; i++) {
            triggerQuantity.push(i)
        }
        let newSelectProductConstant = [...selectProductConstant];
        newSelectProductConstant.push(triggerQuantity);
        setSelectProductConstant(newSelectProductConstant);
        setSelectedProductConstant(prev => [...prev, 0]);
        console.log(newSelectProductConstant);
        setSelected("");
    }

    const handleDelete = (index: number) => {
        let array = [...productNames];
        array.splice(index, 1)
        setProductNames(array);
    }

    const handleProductQuantity = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        let newProductQuantity = [...selectedProductConstant];
        newProductQuantity[index] = Number(e.target.value);
        setSelectedProductConstant(newProductQuantity);
    }

    const handleSupplierQuantity = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedSupplierConstant(Number(e.target.value));
    }

    const submitAutomatic = async () => {
        setSubmit(true);
        if (selectedOffice && selectedSupplier && (selectedDay.length > 0) && selectedHour && selectedMinute && (trigger === "timing" || (trigger === "carriage" && !detailTrigger) || (trigger === "carriage" && detailTrigger && detailTrigger2 === "supplierTrigger") || (trigger === "carriage" && detailTrigger && detailTrigger2 === "productTrigger" && productNames.length > 0))) {
            let dayOfWeek = selectedDay[0];
            for (let i = 1; selectedDay.length > i; i++) {
                dayOfWeek += `,${selectedDay[i]}`;
            }
            const response = await fetch("https://api.poweb.jp/automaticsubmit", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    office: selectedOffice,
                    supplier: selectedSupplier,
                    schedule: `${selectedMinute} ${selectedHour} * * ${dayOfWeek}`,
                    trigger: trigger,
                    detailTrigger: detailTrigger2,
                    supplierConstant: selectedSupplierConstant,
                    triggerProducts: productNames,
                    productConstant: selectedProductConstant
                })
            })
            const data = await response.json();
            alert(data.message);
            if (data.success) {
                navigate("/AutomaticOrderingTable");
            }
        } else {
            alert("入力内容を確認してください。");
        }
    }


    return (
        <div className={styles.div}>
            <h2 className={styles.h2}>自動発注登録</h2>
            <Link to="/AutomaticOrderingTable"><Button variant="contained" size="large" sx={{ position: "absolute", top: 20, right: 50, fontSize: 16, "@media screen and (max-width:959px)": { top: 70, right: 10, fontSize: 14 } }}>自動発注テーブルへ戻る</Button></Link>
            <p className={styles.p}>自動発注を設定する支店を選択してください。</p>
            {(submit && !(selectedOffice)) ? <span className={styles.span}>選択してください。</span> : null}
            <div className={styles.inputWrapper}>
                {office.map((item, index) => (<div className={styles.radio} key={index}><label htmlFor={item} className={styles.label}>{item}</label>
                    <input type="radio" name="office" id={item} value={item} onChange={handleOffice} /></div>
                ))}
            </div>
            <p className={styles.p}>自動発注を設定する発注先を選択してください。</p>
            {(submit && !(selectedSupplier)) ? <span className={styles.span}>選択してください。</span> : null}
            <select id="supplier" onChange={handleSupplier} className={styles.select}>
                <option value="" hidden>--選択--</option>
                {suppliers.map((item, index) => (<option key={index} value={item.supplier}>{item.supplier}</option>))}
            </select>
            <p className={styles.p}>在庫確認する曜日、時刻を設定してください。</p>
            {(submit && (!(selectedDay.length > 0) || !(selectedHour) || !(selectedMinute))) ? <span className={styles.span}>選択してください。</span> : null}
            <div className={styles.inputWrapper}>
                {dayofweek.map((item, index) => (<div className={styles.radio} key={index}><label htmlFor={item.name} className={styles.label}>{item.name}</label>
                    <input type="checkbox" name="dayofweek" id={item.name} value={item.value} onChange={handleDay} required /></div>
                ))}
            </div>
            <label htmlFor="hour" className={styles.label}>実行時刻
                <select name="hour" id="hour" className={styles.select} onChange={handleHour} value={selectedHour}>
                    <option value="" hidden>--</option>
                    {hour.map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>:
                <select name="minute" id="minute" className={styles.select} onChange={handleMinute} value={selectedMinute}>
                    <option value="" hidden>--</option>
                    {minutes.map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </label>
            <p className={styles.p}>発注の条件を設定してください。</p>
            {(submit && !trigger) ? <span className={styles.span}>選択してください。</span> : null}
            {triggerList.map((item, index) => <label key={index} htmlFor={item.id} className={styles.label}>{item.text}<input type="radio" id={item.id} name={item.name} value={item.id} onChange={handleTrigger} /></label>)}
            {trigger === "carriage" ? <div className={styles.div2}><label className={styles.detail}>詳細条件の設定をする<input type="checkbox" onChange={handleDetail} checked={(detailTrigger)} /></label>

                {detailTrigger ? <div className={styles.div3}>
                    <p className={styles.p}>送料無料適用額を満たす前に、定数を設定した製品が指定の数量を下回った場合に発注するよう設定します。</p>
                    {(submit && trigger === "carriage" && detailTrigger && detailTrigger2 === "") ? <span className={styles.span}>選択してください。</span> : null}
                    {detailTriggerList.map((item, index) => <label key={index} className={styles.detail} htmlFor={item.value}>{item.text} <input type="radio" onChange={handleTrigger2} name="detail" id={item.value} value={item.value} checked={(item.value === detailTrigger2)} /></label>)}
                    {(detailTrigger2 === "supplierTrigger") ? (selectSupplierConstant.length > 0) ? <p>残数が<select name="supplierQuantity" onChange={handleSupplierQuantity} value={selectedSupplierConstant}>
                        {selectSupplierConstant.map((item, index) => <option key={index} value={item}>{item}</option>)}
                    </select>以下になった場合発注する。</p> : <p>定数が設定されている製品がありません。</p> : null}
                    {(detailTrigger2 === "productTrigger") ? <div className={styles.div3}>
                        {productNames && <table><thead><tr><th colSpan={3}>適用製品</th></tr></thead><tbody>
                            {(submit && trigger === "carriage" && detailTrigger && detailTrigger2 === "productTrigger" && !(productNames.length > 0)) ? <span className={styles.span}>選択してください。</span> : null}
                            {productNames.map((item, index) => <tr key={index}><td>{item}</td>
                                <td><p className={styles.table_p}>残数が
                                    <select name="productQuantity" onChange={(e) => handleProductQuantity(e, index)} value={selectedProductConstant[index]}>
                                        {selectProductConstant[index].map((item2, index2) => <option key={index2} value={item2}>{item2}</option>)}
                                    </select>
                                    の場合発注</p></td>
                                <td><Button onClick={() => handleDelete(index)} >取り消し</Button></td></tr>)}</tbody></table>}
                        <select name="detail" id="detail" onChange={(e) => pushProducts(e)} value={selected}>
                            <option value="" hidden>--選択--</option>
                            {products.map((item, index) => <option key={index} value={item.productname}>{item.productname}</option>
                            )}
                        </select></div> : null}
                </div> : null}
            </div> : null}
            <Button variant="contained" sx={{ fontSize: 16, margin: 1, "@media screen and (max-width:959px)": { fontSize: 14 } }} size="large" onClick={submitAutomatic}>登録する</Button>
        </div>
    )
}