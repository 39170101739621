import { useEffect, useState } from "react";
import { productData } from "./ConstantRegister";
import { SupplierData } from "./SupplierTable";
import styles from "./Order.module.css"
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export type OrderProduct = {
    productname: string;
    left_right: string;
    size: string;
    color: string;
    cost: number;
    quantity: number;
}

export type OrderData = {
    office: string;
    supplier: string;
    order: OrderProduct[];
    price: number;
}

type setSize = {
    size: string[];
}
type setLeftRight = {
    leftright: string[];
}
type setColor = {
    color: string[];
}

export default function Order() {

    const navigate = useNavigate();
    const [selectedOffice, setSelectedOffice] = useState<string>("");
    const [selectSupplier, setSelectSupplier] = useState<string[]>([]);
    const [selectedSupplier, setSelectedSupplier] = useState<string>("");
    const [products, setProducts] = useState<string[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<string>("");
    const [productData, setProductData] = useState<productData[]>([]);
    const [selectLR, setSelectLR] = useState<setLeftRight[]>([]);
    const [selectedLR, setSelectedLR] = useState<string[]>([]);
    const [selectSize, setSelectSize] = useState<setSize[]>([]);
    const [selectedSize, setSelectedSize] = useState<string[]>([]);
    const [selectColor, setSelectColor] = useState<setColor[]>([]);
    const [selectedColor, setSelectedColor] = useState<string[]>([]);
    const [inputedQuantity, setInputedQuantity] = useState<number[]>([]);

    useEffect(() => {
        const fetchSelectInit = async () => {
            const response = await fetch("https://api.poweb.jp/getsupplier");
            const data = await response.json();
            const suppliers = data.map((item: SupplierData) => item.supplier);
            setSelectSupplier(suppliers);
        }
        fetchSelectInit();
    }, [])

    useEffect(() => {
        const fetchProducts = async () => {
            const response = await fetch("https://api.poweb.jp/getproducts_supplier", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "supplier": selectedSupplier })
            })
            const data = await response.json();
            console.log(data);
            const products = data.map((item: productData) => item.productname);
            setProducts(products);
        }
        fetchProducts();

    }, [selectedSupplier])

    useEffect(() => {
        const fetchProducts = async () => {
            if (selectedProduct === "") {
                return
            }
            const response = await fetch("https://api.poweb.jp/productdata", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "productname": selectedProduct })
            })
            const data = await response.json();
            console.log(data);
            setProductData((prev) => [...prev, data]);
            setSelectLR((prev) => [...prev, { leftright: data.leftright }]);
            setSelectSize((prev) => [...prev, { size: data.size }]);
            setSelectColor((prev) => [...prev, { color: data.color }]);
            setSelectedLR((prev) => [...prev, data.leftright[0]]);
            setSelectedSize((prev) => [...prev, data.size[0]]);
            setSelectedColor((prev) => [...prev, data.color[0]]);
            setInputedQuantity((prev) => [...prev, 1])
            setSelectedProduct("");
        }
        fetchProducts();

    }, [selectedProduct])

    const handleOffice = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOffice(e.target.value);
    }

    const handleSupplier = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedSupplier(e.target.value);
    }

    const handleProduct = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProduct(e.target.value);
    }

    const handleLR = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        let newArray = [...selectedLR];
        const newLR = e.target.value;
        newArray.splice(index, 1, newLR);
        setSelectedLR(newArray);
    }

    const handleSize = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        let newArray = [...selectedSize];
        const newSize = e.target.value;
        newArray.splice(index, 1, newSize);
        setSelectedSize(newArray);
    }

    const handleColor = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        let newArray = [...selectedColor];
        const newColor = e.target.value;
        newArray.splice(index, 1, newColor);
        setSelectedColor(newArray);
    }

    const handleQuantity = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let newArray = [...inputedQuantity];
        const newQuantity = Number(e.target.value);
        newArray.splice(index, 1, newQuantity);
        setInputedQuantity(newArray);
    }

    const resetOffice = () => {
        setSelectedOffice("");
    }

    const resetOrder = () => {
        setSelectedSupplier("");
        setProductData([]);
        setSelectLR([]);
        setSelectSize([]);
        setSelectColor([]);
        setSelectedLR([]);
        setSelectedSize([]);
        setSelectedColor([]);
        setInputedQuantity([])
        setSelectedProduct("");
    }

    const AddShortage = async () => {
        const response = await fetch("https://api.poweb.jp/getshortage", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                office: selectedOffice,
                supplier: selectedSupplier
            })
        })
        const shortage = await response.json();
        // 被りがないかを判定して被りがあった場合加算する処理
        let newQuantity = [...inputedQuantity];
        for (let i = 0; shortage.length > i; i++) {
            let found = false;
            for (let j = 0; productData.length > j; j++) {
                if ((shortage[i].productData.productname === productData[j].productname) && (shortage[i].left_right === selectedLR[j]) && (shortage[i].size === selectedSize[j]) && (shortage[i].color === selectedColor[j])) {
                    newQuantity.splice(j, 1, inputedQuantity[j] + shortage[i].shortage)
                    found = true;
                    break;
                }
            }
            if (found) {
                continue;
            }
            setProductData((prev) => [...prev, shortage[i].productData]);
            setSelectLR((prev) => [...prev, { leftright: shortage[i].productData.leftright }]);
            setSelectSize((prev) => [...prev, { size: shortage[i].productData.size }]);
            setSelectColor((prev) => [...prev, { color: shortage[i].productData.color }]);
            setSelectedLR((prev) => [...prev, shortage[i].left_right]);
            setSelectedSize((prev) => [...prev, shortage[i].size]);
            setSelectedColor((prev) => [...prev, shortage[i].color]);
            newQuantity.push(shortage[i].shortage);
            setSelectedProduct("");
        }
        setInputedQuantity(newQuantity);
    }

    let total = 0;
    for (let i = 0; productData.length > i; i++) {
        total += (productData[i].cost * inputedQuantity[i])
    }

    const cancel = (index: number) => {
        let newData = [...productData];
        newData.splice(index, 1);
        setProductData(newData);
        let newLR = [...selectLR];
        newLR.splice(index, 1);
        setSelectLR(newLR);
        let newSize = [...selectSize];
        newSize.splice(index, 1);
        setSelectSize(newSize);
        let newColor = [...selectColor];
        newColor.splice(index, 1);
        setSelectColor(newColor);
        let newSelectedLR = [...selectedLR];
        newSelectedLR.splice(index, 1);
        setSelectedLR(newSelectedLR);
        let newSelectedSize = [...selectedSize];
        newSelectedSize.splice(index, 1);
        setSelectedSize(newSelectedSize);
        let newSelectedColor = [...selectedColor];
        newSelectedColor.splice(index, 1);
        setSelectedColor(newSelectedColor);
        let newQuantity = [...inputedQuantity];
        newQuantity.splice(index, 1);
        setInputedQuantity(newQuantity);
    }

    const handlePush = async () => {
        if (productData.length < 1) {
            return alert("製品が選択されていません。注文する製品を選択してください。")
        }
        for (let i = 0; productData.length > i; i++) {
            for (let j = i + 1; productData.length > j; j++) {
                if (inputedQuantity[i] < 1) {
                    return alert("個数が0以下に設定されている製品があります。")
                }
                if ((productData[i].productname === productData[j].productname) && (selectedLR[i] === selectedLR[j]) && (selectedSize[i] === selectedSize[j]) && (selectedColor[i] === selectedColor[j])) {
                    return alert("製品の規格に重複があります。削除するか変更してください。")
                }
            }
        }

        let order = [];
        for (let i = 0; productData.length > i; i++) {
            const orderProduct: OrderProduct = {
                productname: productData[i].productname,
                left_right: selectedLR[i],
                size: selectedSize[i],
                color: selectedColor[i],
                cost: productData[i].cost,
                quantity: inputedQuantity[i]
            }
            order.push(orderProduct);
        }
        const orderData = {
            office: selectedOffice,
            supplier: selectedSupplier,
            order: order,
            price: total
        }
        navigate("/Orderconfirm", { state: orderData });
    }

    const handleOrderInfo = async () => {
        if (productData.length < 1) {
            return alert("製品が選択されていません。注文する製品を選択してください。")
        }
        for (let i = 0; productData.length > i; i++) {
            for (let j = i + 1; productData.length > j; j++) {
                if (inputedQuantity[i] < 1) {
                    return alert("個数が0以下に設定されている製品があります。")
                }
                if ((productData[i].productname === productData[j].productname) && (selectedLR[i] === selectedLR[j]) && (selectedSize[i] === selectedSize[j]) && (selectedColor[i] === selectedColor[j])) {
                    return alert("製品の規格に重複があります。削除するか変更してください。")
                }
            }
        }

        let order = [];
        for (let i = 0; productData.length > i; i++) {
            const orderProduct: OrderProduct = {
                productname: productData[i].productname,
                left_right: selectedLR[i],
                size: selectedSize[i],
                color: selectedColor[i],
                cost: productData[i].cost,
                quantity: inputedQuantity[i]
            }
            order.push(orderProduct);
        }
        const orderData: OrderData = {
            office: selectedOffice,
            supplier: selectedSupplier,
            order: order,
            price: total
        }
        const response = await fetch("https://api.poweb.jp/orderinfo", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(orderData)
        })
        const Response = await response.json();
        alert(Response.message);
        navigate("/history");
    }

    return (
        <div className={styles.div}>
            <h2 className={styles.h2}>発注テーブル</h2>
            {(!selectedOffice) ? <div><p className={styles.p}>発注元の支店を選択してください。</p><select onChange={handleOffice} value={selectedOffice} className={styles.select}>
                <option value="" hidden>支店</option>
                <option value="函館店">函館店</option>
                <option value="札幌店">札幌店</option>
                <option value="室蘭店">室蘭店</option>
                <option value="釧路店">釧路店</option>
            </select></div> : <div className={styles.reset}><h3 className={styles.h3}>発注元：{selectedOffice}</h3><Button onClick={resetOffice} variant="contained" sx={{
                fontSize: 16, "@media screen and (max-width:959px)": { fontSize: 12 },
            }}>支店を変更する</Button></div>}
            {(!selectedSupplier) ? <div><p className={styles.p}>発注先を選択してください。</p>
                <select value={selectedSupplier} onChange={handleSupplier} className={styles.select}>
                    <option value="" hidden>発注先</option>
                    {selectSupplier.map((item, index) => (
                        <option key={index} value={item}>{item}</option>))}
                </select></div> : <div className={styles.reset}><h3 className={styles.h3}>発注先：{selectedSupplier}</h3><Button onClick={resetOrder} variant="contained" sx={{
                    fontSize: 16, "@media screen and (max-width:959px)": { fontSize: 12 },
                }}>発注先を変更する</Button></div>
            }
            {(selectedOffice && selectedSupplier) ? <Button onClick={AddShortage} variant="contained" sx={{
                fontSize: 16, "@media screen and (max-width:959px)": { fontSize: 12 },
            }}>在庫不足分を追加する</Button> : null}
            <div className={styles.scroll}>
                <table className={styles.table}>
                    <thead>
                        <tr><th className={styles.th}>製品名</th>
                            <th className={styles.th}>左右</th>
                            <th className={styles.th}>サイズ</th>
                            <th className={styles.th}>色</th>
                            <th className={styles.th}>単価</th>
                            <th className={styles.th}>数量</th>
                            <th className={styles.th}>価格</th></tr>
                    </thead>
                    <tbody>
                        {productData.map((item, index) => <tr key={index}>
                            <td className={styles.td}>{item.productname}</td>
                            <td className={styles.td}><select key={index} value={selectedLR[index]} onChange={(e) => handleLR(e, index)} required className={styles.select}> {selectLR[index].leftright.map((item2, index2) => <option key={index2} value={item2}>{item2}</option>)}</select></td>
                            <td className={styles.td}><select key={index} value={selectedSize[index]} onChange={(e) => handleSize(e, index)} required className={styles.select}>{selectSize[index].size.map((item2, index2) => <option key={index2} value={item2}>{item2}</option>)}</select></td>
                            <td className={styles.td}><select key={index} value={selectedColor[index]} onChange={(e) => handleColor(e, index)} required className={styles.select}>{selectColor[index].color.map((item2, index2) => <option key={index2} value={item2}>{item2}</option>)}</select></td>
                            <td className={styles.td}>{item.cost}</td>
                            <td className={styles.td}><input type="number" onChange={(e) => handleQuantity(e, index)} value={inputedQuantity[index]} className={styles.input} /></td>
                            <td className={styles.td}>{(item.cost * inputedQuantity[index])}</td>
                            <td><Button onClick={() => cancel(index)} sx={{
                                fontSize: 16, "@media screen and (max-width:959px)": { fontSize: 12, width: 70 },
                            }}>取り消し</Button></td>
                        </tr>)}
                        <tr><td className={styles.td}>{(products.length > 0) && <select value={selectedProduct} onChange={handleProduct} className={styles.select}><option value="" hidden>製品</option>{products.map((item, index) => (<option key={index} value={item}>{item}</option>))}</select>}</td><td className={styles.td}></td><td className={styles.td}></td><td className={styles.td}></td><td className={styles.td}></td><td className={styles.td}></td><td className={styles.td}></td></tr>
                        <tr><td colSpan={6} className={styles.td}>発注合計額（税抜）</td><td className={styles.td}>{total}</td></tr>
                    </tbody>
                </table>
            </div>
            {(selectedOffice && selectedSupplier && productData) && <Button onClick={handlePush} variant="contained" sx={{
                margin: 1, fontSize: 16, "@media screen and (max-width:959px)": { fontSize: 12 },
            }}>発注内容を確定してメールで発注する</Button>}
            {(selectedOffice && selectedSupplier && productData) && <Button variant="contained" onClick={handleOrderInfo} sx={{
                margin: 1, fontSize: 16, "@media screen and (max-width:959px)": { fontSize: 12 },
            }}>注文情報を登録する</Button>}
        </div >
    )
}