import { createContext, useState, useEffect, ReactNode, FC } from 'react';

interface AuthState {
    username: string | null;
    isAuthenticated: boolean;
}

export interface AuthContextType {
    auth: AuthState;
    setAuth: React.Dispatch<React.SetStateAction<AuthState>>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [auth, setAuth] = useState<{ username: string | null; isAuthenticated: boolean }>({
        username: null,
        isAuthenticated: false,
    });

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await fetch("https://api.poweb.jp/checkAuth", {
                    method: "POST",
                    credentials: "include",
                    headers: { 'Content-Type': 'application/json' },
                })
                const data = await response.json();
                const username = data.username;
                const result: boolean = data.result;
                console.log(data.message);
                if (result) {
                    setAuth({ username: username, isAuthenticated: true });
                }
                if (!result) {
                    setAuth({ username: null, isAuthenticated: false });
                }
            } catch (error) {
                console.error(error);
            }
        }

        checkAuth();
    }, []);

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };