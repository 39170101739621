import { useLocation } from "react-router-dom"
import { useState, useEffect } from "react";
import { OrderProduct } from "./Order";
import { HistoryData } from "./History";
import styles from "./HistoryDetail.module.css"
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function HistoryDetail() {

    const navigate = useNavigate();
    const location = useLocation();
    const Ordered: HistoryData = location.state;
    const order_id = Ordered.order_id;

    const [detail, setDetail] = useState<OrderProduct[]>([]);

    useEffect(() => {
        const fetchdetail = async () => {
            const response = await fetch("https://api.poweb.jp/gethistorydetail", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ order_id: order_id })
            })
            const data = await response.json();
            setDetail(data);
        }

        fetchdetail();
    }, [order_id])

    const NavigateHistory = () => {
        navigate("/history");
    }

    const handleEntering = async () => {
        const enteringConfirm = window.confirm("入庫処理をせずに、納品日のみを登録します。よろしいですか？");
        if (enteringConfirm) {
            try {
                const response = await fetch("https://api.poweb.jp/submitentering", {
                    method: "PUT",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ order_id: order_id })
                })
                const data = response.json();
                console.log(data);
                alert("納品日を登録しました。")
            } catch (error) {
                console.error(error);
            }
        } else {
            alert("登録をキャンセルしました。")
        }
    }

    const handleDelete = async () => {
        const deleteConfirm = window.confirm("この発注履歴を削除してよろしいですか？");
        if (deleteConfirm) {
            try {
                const response = await fetch("https://api.poweb.jp/deletehistory", {
                    method: "DELETE",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ order_id: order_id })
                })
                const data = await response.json();
                console.log(data);
                alert("発注履歴を削除しました。")
                navigate("/history");
            } catch (error) {
                console.error(error);
            }
        } else {
            alert("削除をキャンセルしました。")
        }
    }

    return (
        <div className={styles.div}>
            <h2>発注履歴明細</h2>
            <Button onClick={NavigateHistory}>発注履歴に戻る</Button>
            <div className={styles.scroll}>
                <table className={styles.table}>
                    <tbody>
                        <tr ><td className={styles.td}>{Ordered.order_at}</td><td className={styles.td}>{Ordered.office}</td><td className={styles.td}>{Ordered.supplier}</td><td className={styles.td}>&yen;{Ordered.totalprice}</td><td className={styles.td}>{Ordered.receiveDate}</td></tr>
                    </tbody>
                </table>
                <table className={styles.table}>
                    <thead><tr><th className={styles.th}>製品名</th><th className={styles.th}>色</th><th className={styles.th}>左右</th><th className={styles.th}>サイズ</th><th className={styles.th}>単価</th><th className={styles.th}>個数</th></tr></thead>
                    <tbody>
                        {detail ? detail.map((item: OrderProduct, index: number) => <tr key={index}>
                            <td className={styles.td}>{item.productname}</td><td className={styles.td}>{item.color}</td><td className={styles.td}>{item.left_right}</td><td className={styles.td}>{item.size}</td><td className={styles.td}>&yen;{item.cost}</td><td className={styles.td}>{item.quantity}</td></tr>)
                            : <tr><td>データを取得できません。</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            <Button onClick={handleEntering} disabled={!(!Ordered.receiveDate)}>納品日を登録</Button>
            <Button onClick={handleDelete}>履歴を削除する</Button>
        </div>
    )
}