import styles from "./OrderConfirm.module.css"
import { useLocation } from "react-router-dom"
import { OrderProduct, OrderData } from "./Order";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export type OrderMail = {
    office: string;
    supplier: string;
    order: OrderProduct[];
    price: number;
    subject: string;
    maintext: string;
}

const offices = [
    {
        office: "函館店",
        address: "〒040-0065\n函館市豊川町15番17号\nTEL 0138-22-2615"
    },
    {
        office: "札幌店",
        address: "〒002-8007\n札幌市北区太平7条2丁目\nTEL 011-774-3022"
    },
    {
        office: "釧路店",
        address: "〒085-0832\n釧路市富士見町1丁目5番9号\nTEL 0154-41-3546"
    },
    {
        office: "室蘭店",
        address: "〒051-0004\n室蘭市母恋北町1- 3 - 6\nTEL 0143-22-5529"
    }]

export default function OrderConfirm() {

    const location = useLocation();
    const orderData: OrderData = location.state;
    console.log(orderData);

    const navigate = useNavigate();

    const [subject, setSubject] = useState<string>("");
    const [maintext, setMaintext] = useState<string>("");

    useEffect(() => {
        const defaultSubject = `注文　株式会社馬場義肢製作所　${orderData.office}`
        setSubject(defaultSubject);
        const orderAddress = (): string => {
            if (orderData.office === "函館店") {
                return offices[0].address
            }
            if (orderData.office === "札幌店") {
                return offices[1].address
            } if (orderData.office === "釧路店") {
                return offices[2].address
            } if (orderData.office === "室蘭店") {
                return offices[3].address
            }
            return ""
        };

        const defaultMaintext = `下記の通り発注いたします。
${orderData.order.map((item: OrderProduct) => `${item.productname} ${item.color} ${item.left_right} ${item.size}×${item.quantity}
`)}\n\n下記住所へ発送願います。\n${orderAddress()}`
        setMaintext(defaultMaintext);
    }, [orderData])

    let total = 0;
    for (let i = 0; orderData.order.length > i; i++) {
        total += (orderData.order[i].cost * orderData.order[i].quantity)
    }

    const handleOrder = async () => {
        const orderMail: OrderMail = {
            ...orderData,
            subject: subject,
            maintext: maintext
        }
        const response = await fetch("https://api.poweb.jp/order", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(orderMail)
        })
        const data = await response.json();
        alert(data.message);
        navigate("/")
    }

    const handlesubject = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSubject(e.target.value);
    }

    const handleText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMaintext(e.target.value);
    }

    return (
        <div className={styles.div}>
            <h2>発注確認</h2>
            <p className={styles.p}>発注元：{orderData.office}</p>
            <table border={1} className={styles.table}>
                <thead><tr className={styles.tr}>
                    <th className={styles.th}>製品名</th>
                    <th className={styles.th}>左右</th>
                    <th className={styles.th}>サイズ</th>
                    <th className={styles.th}>色</th>
                    <th className={styles.th}>単価</th>
                    <th className={styles.th}>数量</th>
                    <th className={styles.th}>価格</th></tr></thead>
                <tbody>
                    {orderData.order.map((item: OrderProduct, index: number) => (
                        <tr key={index}><td>{item.productname}</td>
                            <td className={styles.td}>{item.left_right}</td>
                            <td className={styles.td}>{item.size}</td>
                            <td className={styles.td}>{item.color}</td>
                            <td className={styles.td}>{item.cost}</td>
                            <td className={styles.td}>{item.quantity}</td>
                            <td className={styles.td}>{item.cost * item.quantity}</td></tr>))}
                    <tr><td colSpan={6} className={styles.td}>合計額</td><td className={styles.td}>{total}</td></tr>
                </tbody></table>
            <div className={styles.mailform}>
                <h3 className={styles.h3}>メール内容</h3>
                <p className={styles.p}>送信先：{orderData.supplier}</p>
                <label htmlFor="subject" className={styles.label}>
                    <p className={styles.p}>件名：</p>
                    <input type="text" id="subject" value={subject} onChange={handlesubject} className={styles.input} />
                </label>
                <label htmlFor="maintext" className={styles.label}>
                    <p className={styles.p}>本文：</p>
                    <textarea id="maintext" value={maintext} onChange={handleText} className={styles.textarea}></textarea>
                </label>
                <Button variant="contained" onClick={handleOrder} style={{ margin: 20, fontSize: 16, width: 200 }} >発注メールを送信する</Button>
            </div>
        </div>
    )
}