import { useEffect, useState } from "react";
import { SupplierData } from "./SupplierTable";
import styles from "./Suppliers.module.css"

export default function Suppliers() {

    const [suppliers, setSuppliers] = useState<SupplierData[]>();

    useEffect(() => {
        const fetchSupplier = async () => {
            const response = await fetch("https://api.poweb.jp/getsupplier")
            const data = await response.json();
            setSuppliers(data);
        }

        fetchSupplier();
    }, [])

    return (
        <div className={styles.div}>
            <h2>発注先リスト</h2>
            <div className={styles.scroll}>
                <table className={styles.table}>
                    <thead><tr><th className={styles.th}>発注先</th><th className={styles.th}>メールアドレス</th><th className={styles.th}>送料無料適用額</th></tr></thead>
                    <tbody>
                        {suppliers && suppliers.map((item, index) => (<tr key={index} className={styles.tr}><td className={styles.td}>{item.supplier}</td><td className={styles.td}>{item.email}</td><td className={styles.td}>{item.freeshipping}</td></tr>))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

