import styles from './History.module.css'
import { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export type HistoryData = {
    order_id: number;
    order_at: string;
    supplier: string;
    totalprice: number;
    office: string;
    receiveDate: string;
}

export default function History() {

    const navigate = useNavigate();

    const [historydata, setHistorydata] = useState<HistoryData[]>([]);

    const fetchHistory = async () => {
        const response = await fetch("https://api.poweb.jp/gethistory")
        let data = await response.json();
        const dayofweek = ["（日）", "（月）", "（火）", "（水）", "（木）", "（金）", "（土）"];
        for (let i = 0; i < data.length; i++) {
            const utcDate = new Date(data[i].order_at);
            const utcReceiveDate = new Date(data[i].receiveDate)
            const convert = (dateItem: Date) => {
                const japanOffset = 9 * 60 * 60 * 1000;
                const japanDate = new Date(dateItem.getTime() + japanOffset);
                const year = japanDate.getFullYear();
                const month = japanDate.getMonth() + 1;
                const date = japanDate.getDate();
                const day = dayofweek[japanDate.getDay()];
                const hours = japanDate.getHours();
                const minutes = japanDate.getMinutes();
                const seconds = japanDate.getSeconds();
                const convertDate = {
                    date: `${year}年${month}月${date}日${day}`,
                    time: `${hours}: ${minutes}: ${seconds}`
                }
                return convertDate;
            }
            const appeardate = convert(utcDate);
            data[i].order_at = appeardate.date + appeardate.time;
            if (data[i].receiveDate) {
                const appearReceivedate = convert(utcReceiveDate);
                data[i].receiveDate = appearReceivedate.date;
            }
        }
        setHistorydata(data);
    }

    useEffect(() => {

        fetchHistory();
    }, [])

    const handleDetail = (index: number) => {
        const Ordered = historydata[index];
        navigate("/HistoryDetail", { state: Ordered });
    }

    const handleEntering = async (index: number) => {
        const enteringConfirm = window.confirm("入庫処理をせずに、納品日のみを登録します。よろしいですか？");
        if (enteringConfirm) {
            try {
                const response = await fetch("https://api.poweb.jp/submitentering", {
                    method: "PUT",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ order_id: historydata[index].order_id })
                })
                const data = await response.json();
                console.log(data);
                alert("納品日を登録しました。")
                fetchHistory();
            } catch (error) {
                console.error(error);
            }
        } else {
            alert("登録をキャンセルしました。")
        }
    }


    return (
        <div className={styles.div}>
            <h2 className={styles.h2}>発注履歴</h2>
            <div className={styles.scroll}>
                <table className={styles.table}>
                    <thead><tr><th className={styles.th}>発注日時</th><th className={styles.th}>発注元</th><th className={styles.th}>発注先</th><th className={styles.th}>金額</th><th className={styles.th}>納品日</th></tr></thead>
                    <tbody>
                        {historydata ?
                            historydata.map((item: HistoryData, index: number) => <tr key={index} className={styles.tr}>
                                <td className={styles.td}>{item.order_at}</td><td className={styles.td}>{item.office}</td><td className={styles.td}>{item.supplier}</td><td className={styles.td}> &yen;{item.totalprice}</td><td className={styles.td}>{item.receiveDate}</td><td><Button sx={{
                                    fontSize: 14, "@media screen and (max-width:959px)": { fontSize: 12, width: 110 }
                                }} onClick={() => handleDetail(index)}>注文明細を確認</Button></td><td><Button onClick={() => handleEntering(index)} disabled={!(!item.receiveDate)} sx={{
                                    fontSize: 14, "@media screen and (max-width:959px)": { fontSize: 12, width: 110 }
                                }}>納品日を登録</Button></td></tr>)
                            : <tr><td>データを取得できません。</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}